import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Header = () => (
  <header class={style.header}>
    <a href="/product" class={style.logo}>
      <img
        src="../../assets/imerea.PNG"
        alt="IMERA LOGO"
        height="64"
        width="64"
      />
    </a>
    <nav class={style.nav}>
      <Link activeClassName={style.active} class={style.hlink}href="/product">
        Product
      </Link>
      <Link activeClassName={style.active} class={style.hlink} href="/contact">
        Contact
      </Link>
      <Link activeClassName={style.active} class={style.hlink} href="/about">
        About Us
      </Link>
    </nav>
  </header>
);

export default Header;
