import { h } from "preact";
import { Router } from "preact-router";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Product from "../routes/product";
import Contact from "../routes/contact";
import About from "../routes/about";
import Plans from "../routes/plans";

const App = () => (
  <div id="app">
    <main>
      <Header />
      <Router>
        <Product path="/" />
        <Product path="/product" />
        <Contact path="/contact" />
        <About path="/about" />
        <Plans path="/plans" />
      </Router>
    </main>
  </div>
);

const firebaseConfig = {
  apiKey: "AIzaSyAiJhSI6nVZd63jdtGDOHs5SrjhsY4rYr0",
  authDomain: "imera-website.firebaseapp.com",
  projectId: "imera-website",
  storageBucket: "imera-website.appspot.com",
  messagingSenderId: "501965625731",
  appId: "1:501965625731:web:0b85d8a73d5119f9e4feb6",
  measurementId: "G-X3Z31EYCY1"
};

// prevent prerendering
if (typeof window !== "undefined") {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}


export default App;
